import React from 'react'
import styles from '../styles/GraphBody.module.scss'
import Graph from './Graph'
import Game from './Game'
export default function GraphBody({activeAsset,activeType}) {
  return (
    <div className={styles.GraphBody}>
        <Graph active={activeAsset} activeType={activeType}/>
    </div>
  )
}
