import React, { useEffect, useState } from 'react'
import styles from '../styles/MainBody.module.scss'
import AssetsList from './AssetsList'
import CenterBlock from './CenterBlock'
import Panel from './Panel'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
export default function MainBody({setProfit,i18n,t,handleClick, setSnackType,setSnackMessage}) {
  const [activeAsset ,setActiveAsset] = useState(null)
  const [currentRecords ,setCurrentRecords] = useState([])
  const [favorites, setFavorites] = useState(() => {
    return JSON.parse(secureLocalStorage.getItem('favorites')) || [];
  });

  useEffect(()=>{
    const FetchList = async ()=>{
      try{
        const res = await axios.get('https://trade.exorfingroup.trade:8081/api/assets', {
          headers: {
            'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
          }
        });
        setCurrentRecords(res.data)
      }catch(e){
        console.log(e)
      }
    }
    FetchList()
    const intervalId = setInterval(FetchList, 500);

    return () => {
      clearInterval(intervalId);
    };
  },[])

  const toggleFavorite = (id) => {
    const updatedFavorites = favorites.includes(id)
      ? favorites.filter(favId => favId !== id)
      : [...favorites, id];
    setFavorites(updatedFavorites);
    secureLocalStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  }
  return (
    <div className={styles.MainBody}>
        <div className={styles.List}>
            <AssetsList currentRecords={currentRecords} i18n={i18n} setActiveAsset={setActiveAsset} activeAsset={activeAsset} toggleFavorite={toggleFavorite} favorites={favorites} handleClick={handleClick} setSnackMessage={setSnackMessage} setSnackType={setSnackType}/>
        </div>
        <div className={styles.centerBlock}>
            <CenterBlock activeAsset={activeAsset} currentRecords={currentRecords} setActiveAsset={setActiveAsset} setProfit={setProfit} i18n={i18n} t={t}/>
        </div>
        <div className={styles.Panel}>
          <Panel active={activeAsset} toggleFavorite={toggleFavorite} favorites={favorites} handleClick={handleClick} setSnackMessage={setSnackMessage} setSnackType={setSnackType}/>
        </div>
    </div>
  )
}
