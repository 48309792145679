import React from 'react'
import styles from '../styles/CenterBlock.module.scss'
import GraphBody from './GraphBody'
import HistoryBlock from './HistoryBlock'
export default function CenterBlock({currentRecords,activeAsset, setActiveAsset , setProfit,i18n,t}) {
  console.log(activeAsset?.Type)
  return (
    <div className={styles.CenterBlock}>
        <div className={styles.GraphBlock}>
            <GraphBody activeAsset={activeAsset?.Title} activeType={activeAsset?.Type}/>
        </div>
        <div className={styles.HistoryBlock}>
            <HistoryBlock setProfit={setProfit} AssetsList={currentRecords} setActiveAsset={setActiveAsset} i18n={i18n} t={t}/>
        </div>
    </div>
  )
}
