import styles from '../styles/MobileNavigation.module.scss'
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
function MobileNavigation({ setIsOpen, changeLanguage,balance,margin,profit,i18n, handleOpenModal,handleOpenModalWithdrawal,Logout}) {
    const { t } = useTranslation()

    return (
        <div className={styles.body}>
            <div className={styles.links}>
                <div style={{display:'flex' , alignItems:'center', justifyContent:'center',gap:'10px' , flexWrap:'wrap'}}>
                    <div className={styles.userBalance}>
                        <AccountCircleOutlinedIcon sx={{ color: '#006C9C' }} />
                        <span className={styles.BalanceBody}>
                            <span style={{ color: '#fff' }}>{t('Login')}</span>
                            <span>{secureLocalStorage.getItem('user')?.Login}</span>
                        </span>
                    </div>
                    <div className={styles.userBalance}>
                        <ContactEmergencyOutlinedIcon sx={{color:'#006C9C'}}/>
                        <span className={styles.BalanceBody}>
                        <span style={{color:'#fff'}}>{t('Account')}</span>
                        <span >{secureLocalStorage.getItem('user')?.Id}</span>
                        </span>
                    </div>
                </div>
                <div style={{display:'flex' , alignItems:'center', justifyContent:'center',gap:'10px' , flexWrap:'wrap'}}>
                    <div className={styles.userBalance}>
                        <AccountBalanceWalletOutlinedIcon sx={{color:'#006C9C'}}/>
                        <span className={styles.BalanceBody}>
                        <span style={{color:'#fff'}}>{t('Balance')}</span>
                        <span>{balance?.toFixed(2)}$</span>
                        </span>
                    </div>
                    <div className={styles.userBalance}>
                        <BoltRoundedIcon sx={{color:'#006C9C'}}/>
                        <span className={styles.BalanceBody}>
                        <span style={{color:'#fff'}}>{t('Profit')}</span>
                        <span style={profit < 0? { color: '#006C9C' } : { color: '#15B579' }}>{profit?.toFixed(2)}$</span>
                        </span>
                    </div>
                </div>
                <div style={{display:'flex' , alignItems:'center', justifyContent:'center',flexDirection:'column',gap:'10px' , flexWrap:'wrap'}}>
                <span style={{
              display:"flex",justfiyContent:'space-between',alignItems:"center",
              padding:"1.5vh 3.5vw",color:"#fff",backgroundColor:"#006C9C",
              gap:"0.8vw",borderRadius:"5px",cursor:"pointer",width:'140px',
              fontFamily:"'Roboto',sans-serif",fontWeight:"600", textTransform:"uppercase"
              }} onClick={()=>{handleOpenModal();setIsOpen(false)}}>
              <PaidOutlinedIcon sx={{color:'#fff',width:'28px' , height:'28px'}}/>
              {t("Deposit")}
            </span>
            <span style={{
              display:"flex",justfiyContent:'space-between',alignItems:"center",
              padding:"1.5vh 3.5vw",color:"#fff",backgroundColor:"#006C9C",
              gap:"0.8vw",borderRadius:"5px",cursor:"pointer",width:'140px',
              fontFamily:"'Roboto',sans-serif",fontWeight:"600", textTransform:"uppercase"
              }} onClick={()=>{handleOpenModalWithdrawal();setIsOpen(false)}}>
              <CreditCardOutlinedIcon sx={{color:'#fff',width:'28px' , height:'28px'}} />
              {t("WithdrawalNav")}
            </span>
            <span style={{
              display:"flex",justfiyContent:'space-between',alignItems:"center",
              padding:"1.5vh 3.5vw",color:"#fff",backgroundColor:"#006C9C",
              gap:"0.8vw",borderRadius:"5px",cursor:"pointer",width:'140px',
              fontFamily:"'Roboto',sans-serif",fontWeight:"600", textTransform:"uppercase"
              }} onClick={()=>{Logout();setIsOpen(false)}}>
              <ExitToAppRoundedIcon sx={{color:'#fff',width:'28px' , height:'28px'}} />
              {t("Logout")}
            </span>
                </div>
            </div>
            <CancelIcon style={{ marginTop: '30px', color: 'white', height: '70px', width: '70px', cursor: 'pointer' }} onClick={() => setIsOpen(false)} />
        </div>
    )
}
export default MobileNavigation