import {useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import EditNoteIcon from '@mui/icons-material/EditNote';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

export default function OpenTable({AssetsList,setActiveAsset,data,handleOpenModal,setCurrentRow,i18n,t}){
    const Sell = async (row) => {
        try {
            const { data } = await axios.post(
                `https://trade.exorfingroup.trade:8081/api/assets/${row.AssetId}/close`,
                {
                    user_id: secureLocalStorage.getItem('user')?.Id,
                    asset_id: row.Id
                },
                {
                    headers: {
                        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                    }
                }
            );
            if (data) {
                console.log('success')
            }
        } catch (e) {
            console.log(e)
        }
    }
    const columns = useMemo(
      () => [
        {
          accessorKey: 'Id',
          header: t('OrderId'),
          size: 80, 
        },
        {
          accessorKey: 'Title',
          header: t('Instrument'),
          size: 110, 
          Cell: ({ row }) => {
              return (
                    <span style={{cursor:'pointer'}} onClick={()=> {setActiveAsset(AssetsList.find(el=> el.Title === row.original.Title))
                    }}>
                      {row.original.Title} {row.original.Subtitle}
                    </span>
              )
            },
        },
        {
          accessorKey: 'Status',
          header: t('Type'),
          size: 90,
          Cell: ({ row }) => {
              return (
                    <span style={row.original.Status === 'BUY'? {color:'#6DD881'}: row.original.Status === 'SELL'? {color:'#FF5630'}:{}}>
                      {row.original.Status}
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Price',
          header: t('Margin'),
          size: 110, 
          Cell: ({ row }) => {
              return (
                    <span>
                      {parseFloat((row.original.StartPrice * row.original.Count*row.original.LotSize)/row.original.Leverage).toFixed(2)} $
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'StartPrice',
          header: t('EntryPrice'),
          size: 110, 
          Cell: ({ row }) => {
              return (
                    <span>
                      {row.original.StartPrice?.toFixed(2)} $
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Liquidation',
          header: t('Liquidation'),
          size: 110, 
          Cell: ({ row }) => {
              return (
                    <span style={row.original.Leverage !== 1?{color: '#FF3030'}:{color:'#fff'}}>
                      {row.original.Status === 'BUY'
                              ? row.original.Leverage !== 1?(+row.original.StartPrice - (+row.original.StartPrice.toFixed(4) * (100 / (+row.original.Leverage * 100)))).toFixed(4) + ' $': '-- --'
                              : row.original.Leverage !== 1?(+row.original.StartPrice + (+row.original.StartPrice.toFixed(4) * (100 / (+row.original.Leverage * 100)))).toFixed(4)+ ' $':'-- --'
                          }  
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Leverage',
          header: t('Leverage'),
          size: 80, 
          Cell: ({ row }) => {
              return (
                    <span style={{backgroundColor:'#f86e4f29' , color:'#df7b18' , padding:'4px 8px' , borderRadius:'4px' }}>
                      x{row.original.Leverage} 
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Market',
          header: t('MarketPrice'),
          size: 80, 
          Cell: ({ row }) => {
              return (
                    <span>
                      {(parseFloat(row.original.MarketPrice)).toFixed(4)} $
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Profit',
          header: t('Profit'),
          size: 80, 
          Cell: ({ row }) => {
              return (
                    <span>
                      {
                          row.original.Status === 'BUY' ?
                              <div style={parseFloat(row.original.Profit) > 0 ? { color: 'rgb(119, 237, 139)' } : { color: 'red' }}>
                                      {parseFloat(row.original.Profit).toFixed(3)}$
                              </div>
                              :
                              <div style={parseFloat(row.original.Profit) > 0 ? { color: 'rgb(119, 237, 139)' } : { color: 'red' }}>
                                      {parseFloat(row.original.Profit).toFixed(3)}$
                              </div>
                      }
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'TakeStop',
          header: t('TP&SL'),
          size: 80, 
          enableSorting: false,
          Cell: ({ row }) => {
              return (
                    <span style={{display:'flex' , alignItems:'center',gap:'8px'}}>
                      <span style={{fontSize:'12px'}}>
                          {row.original.TakeProfit?row.original.TakeProfit: '_ _' } / {row.original.StopLoss?row.original.StopLoss:'_ _'}
                      </span>
                      <EditNoteIcon onClick={()=>{setCurrentRow(row.original);handleOpenModal()}} sx={{ color: 'grey', cursor: 'pointer' }} />
                    </span>
              )
            }, 
        },
        {
          accessorKey: 'Actions',
          header: '',
          size: 80, 
          enableSorting: false,
  
          Cell: ({ row }) => {
              return (
                    <button onClick={()=>{Sell(row.original)}} style={row.original.Status === 'BUY' ?
                    parseFloat(row.original.Profit) > 0 ?
                    {fontFamily:'"Roboto" , sans-serif',cursor:'pointer',background:'none', border:'1px solid rgb(119, 237, 139)' , color:'rgb(119, 237, 139)', padding:'8px 12px' , borderRadius:'4px' }
                    :{fontFamily:'"Roboto" , sans-serif',cursor:'pointer',background:'none', border:'1px solid #FF3030' , color:'#FF3030', padding:'8px 12px' , borderRadius:'4px'}
                    :parseFloat(row.original.Profit) * -1 > 0 ?
                    {fontFamily:'"Roboto" , sans-serif',cursor:'pointer',background:'none', border:'1px solid rgb(119, 237, 139)' , color:'rgb(119, 237, 139)', padding:'8px 12px' , borderRadius:'4px' }
                    :{fontFamily:'"Roboto" , sans-serif',cursor:'pointer',background:'none', border:'1px solid #FF3030' , color:'#FF3030', padding:'8px 12px' , borderRadius:'4px'}  }>
                      Close
                    </button>
              )
            }, 
        },
      ],
      [i18n.language,AssetsList],
    )
    
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    initialState: { density: 'compact' },
    getRowId: (row) => row.Id,
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160,
    }, 
    muiTablePaperProps:{
        '& .MuiPaper-root':{
            boxShadow:'none',
        }
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        maxHeight:'150px',
        boxShadow:'none',
        border:'none',
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor:'#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',

          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: '#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',

          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: '#040718',
            
            borderBottom:'1px dashed rgb(46, 50, 54)',
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: '#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',
          },
          '& .MuiDataGrid-footerContainer':{
            display:"none"
        }
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: '#040718',
      draggingBorderColor: '#000',
      boxShadow:'none',
    }),
  muiExpandButtonProps:({row}) =>({
    sx:{
      color:'#fff'

    }
  }),
    muiTableBodyCellProps: ({ column }) => ({
        sx: {
          backgroundColor:'#040718',
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          color:'#fff',
          borderBottom:'1px dashed rgb(46, 50, 54)',
          '& .MuiTableCell-root':{
            boxShadow:'none',
            backgroundColor:'#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)'
          }
        },
      }),
      muiTableProps: ({table})=>({
        sx:{
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            boxShadow:'none',
            '& .MuiDataGrid-footerContainer':{
                display:"none"
            },
            '& .MuiPaper-root':{
                boxShadow:'none',
            }
        }
      }),
      muiTopToolbarProps: ({table})=>({
        sx:{
            display:'none',
          backgroundColor:"#040718",
          borderTop:"1px solid rgb(46, 50, 54)",
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          borderTopLeftRadius:'5px',
          borderTopRightRadius:'5px',
          '& .MuiButtonBase-root':{
            color:'#fff'
          },
          '& .MuiSvgIcon-root':{
            color:'#fff'

          },
          '& .MuiInputBase-root':{
            borderColor:'#fff'

          },
          '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
          }
        }
      }),
      muiColumnActionsButtonProps: ({row})=>({
        sx:{
          color:'#fff'
        }
      }),
     muiTableHeadCellProps: {
        sx: {
          fontWeight: 'bold',
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          backgroundColor:'#040718',
          color:'#fff',
          boxShadow:'none',
          borderBottom:'1px dashed rgb(46, 50, 54)',
          '& .MuiSvgIcon-root':{
            fill:'#fff'
          },
          '& .MuiDivider-root':{
            borderColor:'lightgrey'
          },
          '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
          }
        },
      },
      muiTableHeadProps:{
        sx:{
          '& .MuiTableCell-root':{
            backgroundColor:'#040718',
            border:'none',
            borderTop:"1px solid rgb(46, 50, 54)",
          },
        }
      },
      muiPaginationProps: {
        showRowsPerPage: false,
        shape: 'rounded',
        sx:{
          '& .MuiButtonBase-root':{
            color:'#fff'
          }
        }
      },
      muiBottomToolbarProps:{
        sx:{
            display:'none',
          backgroundColor:'#040718',
          borderBottom:"1px solid rgb(46, 50, 54)",
          borderLeft:"1px solid rgb(46, 50, 54)",
          borderRight:"1px solid rgb(46, 50, 54)",
          color:'#fff'

        },
      },
      muiFilterTextFieldProps:{
        sx:{
          color:'#fff'
        }
      },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
  }
);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table}  />
    </LocalizationProvider> 
};
