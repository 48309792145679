import React, { useEffect, useState } from 'react'
import styles from './Register.module.scss'
import { Link ,useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import logo from '../../imgs/logo.jpg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Select from 'react-select';
import { SingleValue } from 'react-select/animated';
export default function Register() {
    const nav = useNavigate();
    const [showPassword, setShowPassword] = useState(!false);
    const [email, setEmail] = useState('');
    const [login, setLogin] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [serverError, setServerError] = useState('');
    const [countries,setCountries] = useState([]);
    const [country,setCountry] = useState(null);
    const [error, setError] = useState({
        email: false,
        login: false,
        password: false,
        firstName: false,
        middleName: false,
        lastName: false,
        phoneNumber: false
    });
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const validatePhoneNumber = (number) => {
        const regex = /^\+?[1-9]\d{1,14}$/;
        return regex.test(number);
    };
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    
    useEffect(()=>{
        axios.get(`https://trade.exorfingroup.trade:8081/api/countries`).then((res)=>{
            console.log(res);
            var arr = []
            for(let country of res.data){
                arr.push({value:country.Id,label:country.Title});
            }
            setCountries(arr);
        })
    },[])
    const handleChange = (field) => (event) => {
        const { value } = event.target;
        switch (field) {
            case 'email':
                setEmail(value);
                setError({ ...error, email: !validateEmail(value) });
                break;
            case 'login':
                setLogin(value);
                setError({ ...error, login: !value });
                break;
            case 'password':
                setPassword(value);
                setError({ ...error, password: !value });
                break;
            case 'firstName':
                setFirstName(value);
                setError({ ...error, firstName: !value });
                break;
            case 'middleName':
                setMiddleName(value);
                setError({ ...error, middleName: !value });
                break;
            case 'lastName':
                setLastName(value);
                setError({ ...error, lastName: !value });
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                setError({ ...error, phoneNumber: !validatePhoneNumber(value) });
                break;
            default:
                break;
        }
    };
    console.log(country)
    const validateForm = () => {
        const newErrors = {
            email: !email,
            login: !login,
            password: !password,
            firstName: !firstName,
            middleName: !middleName,
            lastName: !lastName,
            phoneNumber: !phoneNumber,
            country: !country
        };
        setError(newErrors);
        return !Object.values(newErrors).some(e => e);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try{

               await axios.post('https://trade.exorfingroup.trade:8081/api/register',{
                    Login:login,
                    Password:password,
                    Email:email,
                    FirstName:firstName,
                    SecondName:middleName,
                    LastName:lastName,
                    PhoneNumber:phoneNumber,
                    Country:country.label
                }).then((res)=>{
                    console.log(res);
                    secureLocalStorage.setItem("token",res.data.token);
                    secureLocalStorage.setItem("user",res.data.user)
                    nav('/',{replace:true});
                }).catch((err)=>{
                    setServerError(err.response.data.message);
                })
            }catch(e){
                console.log(e)
            }
        }
    };
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event);
        }
    };
    const colourStylesDesks = {
        control: (styles) => ({ ...styles, backgroundColor: '#0C101D', width: '98%', minHeight: '34px', border:'none',color:'#fff' }),
        menuList: (styles) => ({ ...styles, background: '#212B36',color:'#fff',fontFamily:'"Open Sans" , sans-serif' }),
        singleValue: (styles)=>({...styles,color:'#fff'}),
        option: (styles, { data, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused || isSelected ? '#0C101D' : undefined,
            ':hover': {
                backgroundColor: '#0C101D',
                color: 'white',
            },
        }),
        input: (styles)=>({...styles, color:'#fff'})
    };
return (
    <div className={styles.page}>
        <div className={styles.login}>
            <div className={styles.header}>
            <div style={{alignSelf:'center', fontWeight:'800',fontSize:'24px' , color:'#fff', fontFamily:"'Open Sans',sans-serif",fontStyle:'italic', display:'flex',flexDirection:'column', alignItems:"center",justifyContent:'center',gap:10}}>
                    <img src={logo}  alt="Logo" style={{width:'60px'}} />
                    ExorfinGroup
                </div>
                <h1 style={{textAlign: "center" , fontSize:'20px',marginBottom:0,marginTop:'8px', color:'#fff'}}>Create an account</h1>
            </div>
                <div className={styles.body} onKeyDown={(event)=>handleEnter(event)}>
                    <div className={styles.Email}>
                        <input placeholder='Email' onChange={handleChange('email')}/>
                        {error.email && (<span className={styles.errorEmail}>Enter valid email please!</span>)}
                    </div>
                    <div className={styles.PhoneNumber}>
                        <input placeholder='Phone number' onChange={handleChange('phoneNumber')}/>
                        {error.phoneNumber && (<span className={styles.errorPhoneNumber}>Enter valid phone number please!</span>)}
                    </div>
                    <div className={styles.Login}>
                        <input placeholder='Login' onChange={handleChange('login')}/>
                        {error.login && (<span className={styles.errorLogin}>Enter login please!</span>)}
                    </div>
                    <div className={styles.Password}>
                        <input placeholder='Password' type={showPassword?'password':'text'} onChange={handleChange('password')}/>
                        <div className={styles.PasswordEye}>
                            {
                                showPassword === false? 
                                <RemoveRedEyeIcon onClick={handleClickShowPassword}/>
                                :<VisibilityOffIcon onClick={handleMouseDownPassword}/>
                            }
                        </div>
                        {error.password && (<span className={styles.errorPassword}>Enter password please!</span>)}
                    </div>
                    <div className={styles.FirstName}>
                        <input placeholder='First Name' onChange={handleChange('firstName')}/>
                        {error.firstName && (<span className={styles.errorFirstName}>Enter first name please!</span>)}
                    </div>
                    <div className={styles.MiddleName}>
                        <input placeholder='Middle Name' onChange={handleChange('middleName')}/>
                        {error.middleName && (<span className={styles.errorMiddleName}>Enter middle name please!</span>)}
                    </div>
                    <div className={styles.LastName}>
                        <input placeholder='Last Name' onChange={handleChange('lastName')}/>
                        {error.lastName && (<span className={styles.errorLastName}>Enter last name please!</span>)}
                    </div>
                    <div className={styles.Countries}>
                        
                                    <Select
                                        closeMenuOnSelect={true}
                                        styles={colourStylesDesks}
                                        options={countries}
                                        onChange={(e) =>
                                            setCountry({label:e.label,value:e.value})
                                        }
                                    >
                                    </Select>
                    </div>
                    <div className={login && password?styles.Button : styles.DisableButton}>
                        <button onClick={handleSubmit}>Register</button>
                    </div>
                        {serverError && (
                            <div className={styles.Error}>
                                    <div className={styles.errorText}>{serverError}</div>
                            </div>
                        )}
                    <div className={styles.Register}>
                        <p>Already have an account?</p>
                        <Link to={'/login'}>Sign in</Link>
                    </div>
                </div>
        </div>
    </div>
)
}
