import React, { useEffect, useState } from 'react'
import styles from '../styles/Panel.module.scss'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useTranslation } from 'react-i18next';
import Leverage from './Leverage';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
export default function Panel({ active, favorites, toggleFavorite ,handleClick, setSnackType,setSnackMessage}) {
  const { t } = useTranslation()
  const [amount, setAmount] = useState('')
  const [leverage , setLeverage] = useState(1)
  const [orderType , setOrderType] = useState('')
  const [takeProfit , setTakeProfit] = useState('')
  const [stopLoss , setStopLoss] = useState('')

  useEffect(()=>{
    switch (active?.Type) {
      case 'crypto': setLeverage(10);break;
      case 'shares': setLeverage(100);break;
      case 'shareIndex': setLeverage(100);break;
      case 'currency': setLeverage(200);break;
      case 'metal': setLeverage(100);break;
      case 'oil_gas': setLeverage(100);break;
      default: setLeverage(1);break;
    }
  },[active])

  const OpenPosition = async()=>{
    if(!orderType){
      handleClick()
      setSnackMessage(t('OrderTypeError'))
      setSnackType('error')
      return
    }
    if(takeProfit && orderType === 'Buy' && +takeProfit <= parseFloat(active.Price)){
      handleClick()
      setSnackMessage(t('MustBeHigher'))
      setSnackType('error')
      return
    }
    if(stopLoss && orderType === 'Buy' && +stopLoss >= parseFloat(active.Price)){
      handleClick()
      setSnackMessage(t('MustBeLower'))
      setSnackType('error')
      return
    }
    if(stopLoss && orderType === 'Sell' && +stopLoss <= parseFloat(active.Price)){
      console.log(takeProfit)
      handleClick()
      setSnackMessage(t('MustBeHigher'))
      setSnackType('error')
      return
    }
    if(takeProfit && orderType === 'Sell' && +takeProfit >= parseFloat(active.Price)){
      handleClick()
      setSnackMessage(t('MustBeLower'))
      setSnackType('error')
      return
    }
    if(orderType === 'Buy'){
      Buy()
    }
    if(orderType === 'Sell'){
      Sell()
    }
  }
  const Buy = async() =>{
    if(!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)){
      handleClick()
      setSnackMessage(t("BuySellError"))
      setSnackType('error')
      return
    }
    try{
      const { data } = await axios.post(
        `https://trade.exorfingroup.trade:8081/api/assets/${active.Id}/buy/long`,
        {
          user_id: secureLocalStorage.getItem('user')?.Id,
          sum: (parseFloat((amount*active?.LotSize) * active?.Price)/leverage).toFixed(2),
          count:amount,
          tp: takeProfit?takeProfit:0,
          sl: stopLoss?stopLoss:0,
          leverage: leverage
        },
        {
          headers: {
            'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
          }
        }
      );
      if(data){
        handleClick()
        setSnackMessage(t('BuySuccess'))
        setSnackType('success')
      }
    }catch(e){
      console.log(e)
      handleClick()
      setSnackMessage(e.response.data.message)
      setSnackType('error')
    }
  }
  const Sell = async() =>{
    if(!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)){
      handleClick()
      setSnackMessage(t("BuySellError"))
      setSnackType('error')
      return
    }
    try{
      const { data } = await axios.post(
        `https://trade.exorfingroup.trade:8081/api/assets/${active.Id}/sell/short`,
        {
          user_id: secureLocalStorage.getItem('user')?.Id,
          sum: (parseFloat((amount*active?.LotSize) * active?.Price)/leverage).toFixed(2),
          count:amount,
          tp: takeProfit?takeProfit:0,
          sl: stopLoss?stopLoss:0,
          leverage: leverage
        },
        {
          headers: {
            'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
          }
        }
      );
      if(data){
        handleClick()
        setSnackMessage(t('SellSuccess'))
        setSnackType('success')
      }
    }catch(e){
      console.log(e)
      handleClick()
      setSnackMessage(e.response.data.message)
      setSnackType('error')
    }
  }

  return (
    <div className={styles.Panel}>
      <div className={styles.header}>
        <h2 style={{wordBreak:'break-all'}}>{active?.Title}{active?.Subtitle}</h2>
        {favorites?.includes(active?.Id) ? (
          <StarRoundedIcon sx={{ color: '#006C9C', width: "40px", height: '40px' }} onClick={() => toggleFavorite(active?.Id)} />
        ) : (
          <StarBorderRoundedIcon sx={{ color: '#006C9C', width: "40px", height: '40px' }} onClick={() => toggleFavorite(active?.Id)} />
        )}
      </div>
      <div className={styles.Inputs}>
        <div className={styles.AmountInputs}>
      <div className={styles.SearchBlock}>
        <label>{t('Lots')}</label>
        <div className={styles.Search}>
          <input type='number' placeholder={t('Lots')}  value={amount} onChange={e => setAmount(e.target.value)} />
        </div>

      </div>
      <div className={styles.ActiveBlock}>
        <label>{t('Margin')}</label>
        <div className={styles.Search}>
          <input placeholder={t('Amount')} value={(parseFloat((amount*active?.LotSize) * active?.Price)/leverage).toFixed(2)}/>
        </div>
      </div>

        </div>
      {/* <Leverage leverage={leverage} setLeverage={setLeverage} color={'#006C9C'} /> */}

      </div>
      <h3 style={{color:'#fff'}}>Order type</h3>
      <div className={styles.Buttons}>
        <div className={styles.Buy}>
          <button onClick={()=>setOrderType('Buy')} style={orderType === 'Buy'?{backgroundColor:'#15B579'}:{}}>
            <TrendingUpRoundedIcon sx={{width:"30px",height:'30px'}}/>
            Buy
          </button>
        </div>
        <div className={styles.Sell}>
          <button onClick={()=>setOrderType('Sell')} style={orderType === 'Sell'?{backgroundColor:'#C3083F'}:{}}>
            <TrendingDownRoundedIcon sx={{width:"30px",height:'30px'}}/>
            Sell
          </button>
        </div>
      </div>
      <div className={styles.SearchBlock}>
        <label>{t('TakeProfit')}</label>
        <div className={styles.Search}>
          <input type='number' name='take' placeholder={t('Take-profit')}  value={takeProfit}  onChange={e => setTakeProfit(e.target.value)}/>
        </div>
        {/* {takeProfitDirty && takeProfitError?<p style={{color:'red' , fontSize:'12px',marginTop:'4px',marginBottom:'0'}}>{takeProfitError}</p> :''} */}
      </div>
      <div className={styles.SearchBlock}>
        <label>{t('Stop-loss')}</label>
        <div className={styles.Search}>
          <input type='number' placeholder={t('Stop-loss')} value={stopLoss} onChange={e => setStopLoss(e.target.value)} />
        </div>
        {/* {stopLossError? <p style={{color:'red' , fontSize:'12px',marginTop:'4px',marginBottom:'0'}}>{stopLossError}</p>:""} */}
      </div>
      <button onClick={OpenPosition} style={{width:'100%',marginTop:'40px', background:'#006C9C',color:'#fff',fontSize:'18px',fontFamily:"'Open Sans',sans-serif",cursor:'pointer',border:'none',padding:'20px 0',borderRadius:'8px'}}>Open position</button>
    </div>
  )
}
