import React, { useState } from 'react'
import styles from './Login.module.scss'
import { Link , useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import logo from '../../imgs/logo.jpg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
export default function Login() {
    const nav = useNavigate();
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [error, setError]=useState('');
    const handleLoginChange = (event) => {
        setError('')
        const value = event.target.value;
        setLogin(value);
        if (!value) {
            setErrorLogin(true);
        } else {
            setErrorLogin(false);
        }
    };
    const handlePasswordChange = (event) => {
        setError('')
        const value = event.target.value;
        setPassword(value);
        if (!value) {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }
    };
    const validateForm = () => {
        if (!login) setErrorLogin(true);
        if (!password) setErrorPassword(true);
        return !errorLogin && !errorPassword;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(validateForm())
        if (validateForm()) {
            axios.post('https://trade.exorfingroup.trade:8081/api/login',{
                Login:login,
                Password:password
            }).then((res)=>{
                console.log(res);
                secureLocalStorage.setItem("token",res.data.token);
                secureLocalStorage.setItem("user",res.data.user)
                nav('/',{replace:true});
            }).catch((err)=>{
                setError(err.response.data.message);
            })
        }
    };
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event);
        }
    };

return (
    <div className={styles.page}>
        <div className={styles.login}>
            <div className={styles.header}>
                <div style={{alignSelf:'center', fontWeight:'800',color:'#fff' , fontSize:'24px', fontFamily:"'Open Sans',sans-serif",fontStyle:'italic', display:'flex',flexDirection:'row', alignItems:"center",justifyContent:'start',gap:30}}>
                    <img src={logo}  alt="Logo" style={{width:'60px'}} />
                    ExorfinGroup
                </div>
                {/* <h1 style={{textAlign: "center"}}>Welcome</h1> */}
            </div>
            <div className={styles.body} onKeyDown={(event)=>handleEnter(event)}>
                <div className={styles.Login}>
                    <input placeholder='Login' onChange={e=>handleLoginChange(e)}/>
                    {errorLogin && (<span className={styles.errorLogin}>Enter login please!</span>)}
                </div>
                <div className={styles.Password}>
                    <input placeholder='Password' type={showPassword?'password':'text'} onChange={e=> handlePasswordChange(e)}/>
                    <div className={styles.PasswordEye}>
                        {
                            showPassword === false? 
                            <RemoveRedEyeIcon onClick={handleClickShowPassword}/>
                            :<VisibilityOffIcon onClick={handleMouseDownPassword}/>
                        }
                    </div>
                    {errorPassword && (<span className={styles.errorPassword}>Enter password please!</span>)}
                </div>
                <div className={login && password?styles.Button : styles.DisableButton}>
                    <button onClick={handleSubmit}>Login</button>
                </div>
                    {error && (
                        <div className={styles.Error}>
                                <div className={styles.errorText}>{error}</div>
                        </div>
                    )}
                <div className={styles.Register}>
                    <p>Don’t have an account?</p>
                    <Link to={'/register'}>Sign up</Link>
                </div>

            </div>
        </div>
    </div>
)
}
