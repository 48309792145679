import styles from './MainPage.module.scss'
import MainBody from '../../components/MainBody';
import NavBar from '../../components/NavBar';
import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function MainPage({margin,balance,handleClick,setSnackMessage,setSnackType,}) {
  const [profit , setProfit] = useState(0)

const { t,i18n } = useTranslation();
function changeLanguage(e) {
    i18n.changeLanguage(e);
  }

  if(!secureLocalStorage.getItem('user')){
    return <Navigate to={'/login'}/>
  }else{
  return (
    <div className={styles.MainPage}>
      <NavBar profit={profit} margin={margin} balance={balance} i18n={i18n} t={t} changeLanguage={changeLanguage} handleClick={handleClick} setSnackMessage={setSnackMessage} setSnackType={setSnackType}/>
      <MainBody setProfit={setProfit} i18n={i18n} t={t} handleClick={handleClick} setSnackMessage={setSnackMessage} setSnackType={setSnackType}/>
    </div>
  );  
}
}

export default MainPage;
