
import React , { useEffect, useState } from 'react';
import './App.css';
import MainBody from './components/MainBody';
import NavBar from './components/NavBar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { SciChartSurface, SciChart3DSurface } from "scichart";
import { Snackbar } from '@mui/material';
import i18n from './i18n/config';
import MuiAlert from '@mui/material/Alert';
import AutoLogin from './pages/AutoLogin/AutoLogin';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} sx={{fontFamily:'"Open Sans" , sans-serif'}} />;
});

function App() {
  const [balance , setBalance] = useState(0)
  const [margin , setMargin] = useState(0)
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  useEffect(()=>{
    setInterval(async ()=>{
      if(secureLocalStorage.getItem('user'))
      axios.get(`https://trade.exorfingroup.trade:8081/api/users/${secureLocalStorage.getItem('user')?.Id}`,{
        headers:{
            'Authorization':'Bearer '+secureLocalStorage.getItem('token')
        }
    })
    .then((res)=>{
        secureLocalStorage.setItem('user',res.data.user);
        secureLocalStorage.setItem('token',res.data.token);
        setBalance(res.data.user.Balance)
        setMargin(res.data.user.Margin);
    })
    .catch((err)=>{
        console.log(err);
    })
    },2000)
    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("en");
    secureLocalStorage.setItem("lang","en");
    }
  },[])


  SciChartSurface.useWasmFromCDN();
  SciChart3DSurface.useWasmFromCDN();
    return (
      <div className="App">
        <Routes>
          <Route path={'/'} element={<MainPage margin={margin} balance={balance} handleClick={handleClick} setSnackMessage={setSnackMessage} setSnackType={setSnackType}/>}></Route>
          <Route path={'/login'} element={<Login/>}></Route>
          <Route path={'/register'} element={<Register/>}></Route>
          <Route path={'/autologin/:token/:email'} element={<AutoLogin/>}></Route>
        </Routes>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackType} sx={{ width: '100%', fontFamily: '"Open Sans" , sans-serif' }}>
            {snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );

}

export default App;
