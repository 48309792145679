import React, { useState, useRef, useEffect } from "react";
import {
    SciChartSurface,
    NumberRange,
    NumericAxis,
    OhlcDataSeries,
    FastCandlestickRenderableSeries,
    ZoomPanModifier,
    ZoomExtentsModifier,
    MouseWheelZoomModifier,
    ENumericFormat,
    DateTimeNumericAxis,
    EAutoRange,
    CursorModifier,
    EDataSeriesType,
    FastOhlcRenderableSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    AxisMarkerAnnotation,
    ELineDrawMode,
    libraryVersion,
    CategoryAxis,
} from "scichart";
import { CircularProgress } from '@mui/material';
import { appTheme } from "scichart-example-dependencies";
import classes from "../styles/Graph.module.scss";
import useWebSocket from "react-use-websocket";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import logo from '../imgs/logo.jpg'
import dayjs from "dayjs";
import scichart2d from 'scichart/_wasm/scichart2d.data'
import scichart2dWasm from 'scichart/_wasm/scichart2d.wasm'
import { useTranslation } from "react-i18next";
const divElementId = "chart";
const Y_AXIS_VOLUME_ID = "Y_AXIS_VOLUME_ID";

const drawExample = async (history,active) => {
    SciChartSurface.configure({
        dataUrl: scichart2d,
        wasmUrl: scichart2dWasm
      });
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
        theme: appTheme.SciChartJsTheme
    });
    sciChartSurface.background = "#0C101D";
    const xAxis = new CategoryAxis(wasmContext, {
        // autoRange: EAutoRange.Never,
        drawMajorTickLinesProperty:false,
        drawMajorGridLines:true,
        drawMinorTickLinesProperty:false,
        drawMinorGridLines: false,
        majorGridLineStyle: {color:"rgba(82, 83, 83, 0.136)", strokeDashArray:ELineDrawMode.Solid,strokeThickness: 1},
        labelFormat: ENumericFormat.Date_DDMMHHMM
    });
    sciChartSurface.xAxes.add(xAxis);

    const yAxis = new NumericAxis(wasmContext, {
        growBy: new NumberRange(0.1, 0.1),
        labelFormat: ENumericFormat.Decimal,
        labelPrecision: history[history.length-1].close>1?2:4,
        labelPrefix: "$",
        autoRange: EAutoRange.Always,
        drawMajorTickLinesProperty:false,
        drawMajorGridLines:  true,
        drawMinorTickLinesProperty:false,
        drawMinorGridLines:  false,
        majorGridLineStyle: {color:"rgba(82, 83, 83, 0.136)", strokeDashArray:ELineDrawMode.Solid,strokeThickness: 1},
    });
    sciChartSurface.yAxes.add(yAxis);

    sciChartSurface.yAxes.add(
        new NumericAxis(wasmContext, {
            id: Y_AXIS_VOLUME_ID,
            growBy: new NumberRange(0, 4),
            isVisible: false,
            autoRange: EAutoRange.Always
        })
    );

    const endDate = new Date(Date.now());
    const startDate = new Date();
    startDate.setHours(endDate.getHours() - 300);
    const priceBars = history;

    const xValues = [];
    const openValues = [];
    const highValues = [];
    const lowValues = [];
    const closeValues = [];
    const volumeValues = [];
    priceBars.forEach(priceBar => {
        xValues.push(moment(priceBar.time).utc(true).unix());
        openValues.push(priceBar.open);
        highValues.push(priceBar.high);
        lowValues.push(priceBar.low);
        closeValues.push(priceBar.close);
        volumeValues.push(priceBar.volume);
    });

    const startViewportRange = new Date();
    startViewportRange.setHours(startDate.getHours() - 100);
    xAxis.visibleRange = new NumberRange(startViewportRange.getTime() / 1000, endDate.getTime() / 1000);

    const candleDataSeries = new OhlcDataSeries(wasmContext, {
        xValues,
        openValues,
        highValues,
        lowValues,
        closeValues,
        dataSeriesName: active
    });
    const candlestickSeries = new FastCandlestickRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        stroke: appTheme.ForegroundColor,
        strokeThickness: 1,
        brushUp: "#15B579",
        brushDown: "#C3083F",
        strokeUp: "#15B579",
        strokeDown: "#C3083F"
    });
    sciChartSurface.renderableSeries.add(candlestickSeries);

    const ohlcSeries = new FastOhlcRenderableSeries(wasmContext, {
        dataSeries: candleDataSeries,
        stroke: appTheme.ForegroundColor,
        strokeThickness: 1,
        dataPointWidth: 0.9,
        strokeUp: appTheme.VividGreen,
        strokeDown: appTheme.MutedRed,
        isVisible: false
    });
    sciChartSurface.renderableSeries.add(ohlcSeries);
    if (history.length > 100) {
        xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
    } else {
        xAxis.visibleRange = new NumberRange(0, history.length - 1);
    }
    sciChartSurface.chartModifiers.add(
        new ZoomExtentsModifier(),
        new ZoomPanModifier(),
        new MouseWheelZoomModifier(),
        new CursorModifier({
            crosshairStroke: appTheme.VividOrange,
            axisLabelFill: appTheme.VividOrange,
            tooltipLegendTemplate: getTooltipLegendTemplate
        })
    );

    return { sciChartSurface, candlestickSeries, ohlcSeries };
};



const getTooltipLegendTemplate = (seriesInfos, svgAnnotation) => {
    let outputSvgString = "";
    seriesInfos.forEach((seriesInfo, index) => {
        const y = 20 + index * 20;
        const textColor = seriesInfo.stroke;
        let legendText = seriesInfo.formattedYValue;
        if (seriesInfo.dataSeriesType === EDataSeriesType.Ohlc) {
            const o = seriesInfo;
            legendText = `Open=${o.openValue} High=${o.highValue} Low=${o.lowValue} Close=${o.closeValue} Time=${moment(o.xValue * 1000).utc(false).format("YYYY-MM-DD HH:mm:ss")}`;
            
        }
        outputSvgString += `<text x="8" y="${y}" font-size="13" font-family="'Open Sans', sans-serif" fill="${textColor}">
        ${seriesInfo.seriesName}: ${legendText}
        </text>`;
    });

    return `<svg width="100%" height="100%">
                ${outputSvgString}
            </svg>`;
};

function IntervalSelector({ interval,onIntervalChange, activeType,t }) {
    if(activeType === 'ruShare'){
        onIntervalChange(60,'1m')
    }
    const buttonStyle={fontSize:'16px' , fontFamily:'"Open Sans" ,sans-serif',background:'none',padding:'6px 10px' , color:"#fff",cursor:'pointer',borderRadius:"6px", border:"1px solid #006C9C"}
    const buttonStyleActive={fontSize:'16px' , fontFamily:'"Open Sans" ,sans-serif', backgroundColor:"#006C9C" , padding:'5px 10px' , color:"#fff",cursor:'pointer',borderRadius:"6px", border:"1px solid #006C9C"}
    return (
        <div style={{width:'100%' ,backgroundColor:'#040718',padding:'4px 10px 10px 0' , display:'flex',justifyContent:'start' , alignItems:'start',gap:'10px',flexWrap:'wrap'}}>
            <button style={buttonStyleActive}>{t('Intervals')}:</button>
            <button onClick={()=>{onIntervalChange(60,'1m')}} style={interval==60?buttonStyleActive:buttonStyle}>1m</button>
            {
                activeType !== 'ruShare' && (
                    <div style={{width:'60%' ,backgroundColor:'#040718' , display:'flex' , alignItems:'center',justifyContent:'start',gap:'10px',flexWrap:'wrap'}}>
                        <button onClick={()=>{onIntervalChange(5*60,'5m')}} style={interval==5*60?buttonStyleActive:buttonStyle}>5m</button>
                        <button onClick={()=>{onIntervalChange(15*60,'15m')}} style={interval==15*60?buttonStyleActive:buttonStyle}>15m</button>
                        <button onClick={()=>{onIntervalChange(30*60,'30m')}} style={interval==30*60?buttonStyleActive:buttonStyle}>30m</button>
                        <button onClick={()=>{onIntervalChange(60*60,'1h')}} style={interval==60*60?buttonStyleActive:buttonStyle}>1h</button>
                        <button onClick={()=>{onIntervalChange(240*60,'4h')}} style={interval==240*60?buttonStyleActive:buttonStyle}>4h</button>
                        <button onClick={()=>{onIntervalChange(1440*60,'1d')}} style={interval==1440*60?buttonStyleActive:buttonStyle}>1d</button>
                        <button onClick={()=>{onIntervalChange(10080*60,'1w')}} style={interval==10080*60?buttonStyleActive:buttonStyle}>1w</button>
                        <button onClick={()=>{onIntervalChange(40320*60,'1mo')}} style={interval==40320*60?buttonStyleActive:buttonStyle}>1M</button>
                    </div>
                )
            }
        </div>
    );
}
function Graph({active , activeType}) {
    const {t} = useTranslation()
    const sciChartSurfaceRef = useRef();
    const [interval, setInterval] = useState(60);
    const [intervalLabel, setIntervalLabel] = useState('1m');
    const [candlestickChartSeries, setCandlestickChartSeries] = useState();
    const [ohlcChartSeries, setOhlcChartSeries] = useState();
    //code
    const [history,setHistory] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingChart, setIsLoadingChart] = useState(false);
    const socketUrl = 'wss://trade.exorfingroup.trade:8055/ws/plot/';
    const [socket,SetSocket] = useState('');
    const handleIntervalChange = (newInterval ,label) => {
        setInterval(newInterval);
        setIntervalLabel(label)
    };
    const { sendMessage, lastMessage, getWebSocket } = useWebSocket(socketUrl, {
        onOpen: () => sendMessage(socket), // Отправляем сообщение сразу после подключения
        shouldReconnect: (closeEvent) => true, // Автоматическое переподключение
        onMessage: (event) => {
        try {
        setIsLoading(false);
        const messageData = JSON.parse(event.data);
        const newCandle = Array.isArray(messageData) ? messageData[0] : messageData;
        const timeInMs = new Date(newCandle.time).getTime();
        if (!isNaN(timeInMs)) {
            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            const localTime = timeInMs - timezoneOffset;
            const newCandleData = {
                time: localTime / 1000, // Преобразование в UNIX timestamp
                open: newCandle.open,
                high: newCandle.high,
                low: newCandle.low,
            close: newCandle.close,
            };
            if (messageData.length > 1) {
              // Исторические данные
            setIsLoadingChart(true);
                setHistory(messageData);
                if(sciChartSurfaceRef){
                    const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                    // const lastDataPointTimestamp = history[history.length - 1].time; 
                    // const zoomRangeSeconds = interval; // Переводим часы в секунды
                    // const visibleRangeMin = lastDataPointTimestamp - zoomRangeSeconds;
                    // const visibleRangeMax = lastDataPointTimestamp;
                    // xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
                    // sciChartSurfaceRef.current.zoomExtents();
                    if (history.length > 100) {
                        xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
                    } else {
                        xAxis.visibleRange = new NumberRange(0, history.length - 1);
                    }
                }
            } else {
                    const lastCandleIndex = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.count() - 1;
                    const newCandleTime = moment(new Date(newCandle.time)).utc(true).unix();
                    const valueslow = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeLowValues(); 
                    const valueshigh = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeHighValues(); 
                    const valuesy = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.yValues; 
                    const valuesx = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues(); 
                    const valuesclose = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeCloseValues(); 
                    const valuesopen = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeOpenValues(); 

                    if((dayjs(newCandleTime)-dayjs(valuesx.get(lastCandleIndex)))==interval){
                        sciChartSurfaceRef.current.annotations.clear();
                        sciChartSurfaceRef.current.annotations.add(
                            new HorizontalLineAnnotation ({
                                labelPlacement: ELabelPlacement.Axis,
                                showLabel: false,
                                stroke: newCandle.close<newCandle.open?"#C3083F":"#15B579",
                                strokeThickness: 2,
                                y1: newCandle.close,
                                axisLabelFill: newCandle.close<newCandle.open?"#C3083F":"#15B579",
                            }),
                                new AxisMarkerAnnotation({
                                    color:"#fff",
                                    backgroundColor:newCandle.close<newCandle.open?"#C3083F":"#15B579",
                                  y1: newCandle.close,
                                  fontSize: 12,
                                  fontStyle: "Bold",
                              }),
                        );
                        sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(valuesx.get(lastCandleIndex)+interval,newCandle.open,newCandle.high,newCandle.low,newCandle.close);
                        sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.update({
                            index:+lastCandleIndex-1,
                            close:newCandle.open
                        });
                    }
                    else if((dayjs(newCandleTime)-dayjs(valuesx.get(lastCandleIndex)))<interval){
                        let temp = {
                            time:valuesx.get(lastCandleIndex),
                            open:valuesopen.get(lastCandleIndex),
                            close:valuesclose.get(lastCandleIndex),
                            high:valueshigh.get(lastCandleIndex),
                            low:valueslow.get(lastCandleIndex)
                        }
                        sciChartSurfaceRef.current.annotations.clear();
                        sciChartSurfaceRef.current.annotations.add(
                            // Horizontal line stretched across the viewport
                            new HorizontalLineAnnotation ({
                                labelPlacement: ELabelPlacement.Axis,
                                showLabel: false,
                                stroke: newCandle.close<temp.open?"#C3083F":"#15B579",
                                strokeThickness: 2,
                                y1: newCandle.close,
                                axisLabelFill: newCandle.close<temp.open?"#C3083F":"#15B579",
                            }),
                                new AxisMarkerAnnotation({
                                    color:"#fff",
                                    backgroundColor:newCandle.close<temp.open?"#C3083F":"#15B579",
                                  y1: newCandle.close,
                                  fontSize: 12,
                                  fontStyle: "Bold",
                              }),
                          
                        );
                        sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.removeAt(lastCandleIndex);
                        sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(
                            temp.time,
                            temp.open,
                            Math.max(newCandle.high,temp.high),
                            Math.min(newCandle.low,temp.low),
                            newCandle.close
                        );
                    }
                    else {
                        sciChartSurfaceRef.current.annotations.clear();
                        sciChartSurfaceRef.current.annotations.add(
                            new HorizontalLineAnnotation ({
                                labelPlacement: ELabelPlacement.Axis,
                                showLabel: false,
                                stroke: newCandle.close<newCandle.open?"#C3083F":"#15B579",
                                strokeThickness: 2,
                                y1: newCandle.close,
                                axisLabelFill: newCandle.close<newCandle.open?"#C3083F":"#15B579",
                            }),
                                new AxisMarkerAnnotation({
                                    color:"#fff",
                                    backgroundColor:newCandle.close<newCandle.open?"#C3083F":"#15B579",
                                  y1: newCandle.close,
                                  fontSize: 12,
                                  fontStyle: "Bold",
                              }),
                        );
                        sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.append(newCandleTime,newCandle.open,newCandle.high,newCandle.low,newCandle.close);
                        const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                        const visibleRange = xAxis.visibleRange;
                        if (newCandleTime > visibleRange.max) {
                            // const maxVisibleRange = newCandleTime;
                            // const minVisibleRange = maxVisibleRange - (visibleRange.max - visibleRange.min); // Сдвигаем начало диапазона так же, как и конец
                            // xAxis.visibleRange = new NumberRange(minVisibleRange, maxVisibleRange);
                            if (history.length > 100) {
                                xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
                            } else {
                                xAxis.visibleRange = new NumberRange(0, history.length - 1);
                            }
                        }
                    }
                } 
        } else {
            console.error('Received time is invalid:', newCandle.time);
        }
        } catch (error) {
            console.error('Failed to parse incoming data', error);
        }
    },
    });
    const [originalData, setOriginalData] = useState({
        valueslow: null,
        valueshigh: null,
        valuesclose: null,
        valuesopen: null,
        valuesx: null
    });

    useEffect(()=>{
        if(history?.length>0){
            setIsLoadingChart(false)
            const chartInitializationPromise = drawExample(history,active).then(
                ({ sciChartSurface,  candlestickSeries, ohlcSeries }) => {
                    setCandlestickChartSeries(candlestickSeries);
                    setOhlcChartSeries(ohlcSeries);
                    sciChartSurfaceRef.current = sciChartSurface;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.x = 1;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.y = 1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.count.value = -1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.ptr = 1;
                    sciChartSurfaceRef.current.watermarkProperties.$$.m_fCanvasWidth = 0;
                    
                    sciChartSurfaceRef.current.watermarkPropertyPosition.$$.count.value = -1;
                    sciChartSurfaceRef.current.watermarkPropertyPosition.$$.ptr = 1;
                    sciChartSurfaceRef.current.watermarkRelativeToCanvas = false;
                    setOriginalData({
                        valueslow: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeLowValues(),
                        valueshigh: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeHighValues(),
                        valuesclose: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeCloseValues(),
                        valuesopen: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeOpenValues(),
                        valuesx: sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.getNativeXValues()
                    });
                    const xAxis = sciChartSurfaceRef.current.xAxes.get(0);
                    // const lastDataPointTimestamp = history[history.length - 1].time; 
                    // const zoomRangeSeconds = interval; // Переводим часы в секунды
                    // const visibleRangeMin = lastDataPointTimestamp - zoomRangeSeconds;
                    // const visibleRangeMax = lastDataPointTimestamp;
            
                    // xAxis.visibleRange = new NumberRange(visibleRangeMin, visibleRangeMax);
                    if (history.length > 100) {
                        xAxis.visibleRange = new NumberRange(history.length - 100, history.length - 1);
                    } else {
                        xAxis.visibleRange = new NumberRange(0, history.length - 1);
                    }
                    sciChartSurfaceRef.current.zoomExtents();
                }
            );
            return () => {
                if (sciChartSurfaceRef.current) {
                    sciChartSurfaceRef.current.delete();
                    sciChartSurfaceRef.current = undefined;
                    return;
                }
                chartInitializationPromise.then(() => {
                    sciChartSurfaceRef.current.delete();
                    sciChartSurfaceRef.current = undefined;
                });
            };
        }
    },[history])
    useEffect(() => {
        if(getWebSocket()){

            setIsLoadingChart(true);
            setIsLoading(true);
            getWebSocket()?.close();
        axios.post('https://trade.exorfingroup.trade:8081/api/users/socket',{
            message:`${active},${intervalLabel}`
        },  
        {
        headers:{
        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
        }
    }
    ).then((res)=>{
        SetSocket(res.data);
        sendMessage(res.data);
    })
}
        if (sciChartSurfaceRef.current) {
            const { valueslow, valueshigh, valuesclose, valuesopen, valuesx } = originalData;

            const intervalSeconds = interval;
    
            const groupedData = {
                xValues: [],
                openValues: [],
                highValues: [],
                lowValues: [],
                closeValues: []
            };
    
            for (let i = 0; i <  sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.count(); i += intervalSeconds) {
                if (i + intervalSeconds > sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries.count()) break;
                const firstCandleIndex = i;
                const lastCandleIndex = i + intervalSeconds - 1;
                const xValue = valuesx.get(firstCandleIndex); // метка времени первой свечи в группе
                const openValue = valuesopen.get(firstCandleIndex); // значение открытия первой свечи
                const closeValue = valuesclose.get(lastCandleIndex); // значение закрытия последней свечи
                let highValue = valueshigh.get(firstCandleIndex);
                let lowValue = valueslow.get(firstCandleIndex);
                for (let j = firstCandleIndex; j <= lastCandleIndex; j++) {
                    highValue = Math.max(highValue, valueshigh.get(j));
                    lowValue = Math.min(lowValue, valueslow.get(j));
                }
                groupedData.xValues.push(xValue);
                groupedData.openValues.push(openValue);
                groupedData.highValues.push(highValue);
                groupedData.lowValues.push(lowValue);
                groupedData.closeValues.push(closeValue);
            }
            const dataSeries = sciChartSurfaceRef.current.renderableSeries.items[0].dataSeries;
            dataSeries.clear();
            dataSeries.appendRange(
                groupedData.xValues, 
                groupedData.openValues, 
                groupedData.highValues, 
                groupedData.lowValues, 
                groupedData.closeValues.map(value => parseFloat(value))
            );
        
    }
    }, [interval]);
    
    useEffect(()=>{
        setIsLoadingChart(true);
        setIsLoading(true);
        axios.post('https://trade.exorfingroup.trade:8081/api/users/socket',{
            message:`${!active?"Bitcoin":active},${intervalLabel}`
        },  
        {
        headers:{
        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
        }
    }
    ).then((res)=>{
        SetSocket(res.data);
        sendMessage(res.data);
    })
    },[])
    
    useEffect(() => {
    if(getWebSocket()){
        setIsLoadingChart(true);
        setIsLoading(true);
        getWebSocket()?.close();
        axios.post('https://trade.exorfingroup.trade:8081/api/users/socket',{
            message:`${active},${intervalLabel}`
        },  
        {
        headers:{
        'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
        }
        }
        ).then((res)=>{
            SetSocket(res.data);
            sendMessage(res.data);
        })
    }
    }, [active]);



    return (
        <React.Fragment>
            <div className={classes.FullHeightChartWrapper}>
            <IntervalSelector onIntervalChange={handleIntervalChange} interval={interval} activeType={activeType} t={t}/>
            <div style={{width:"100%",height:"100%",display:isLoadingChart?"flex":"none",alignItems:"center",justifyContent:"center", backgroundColor:"transparent",position:"absolute",zIndex:"999",top:"1vh" }}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#006C9C' : '#006C9C'),
                        animationDuration: '1450ms',
                    }}
                    size={80}
                    thickness={4}
                />
            </div> 
            <div style={{
                        position:"absolute", bottom:"0", left:"0",right:"0", top:"0", margin:"auto",
                        display:!isLoadingChart?"flex":"none",fontSize:"10.5vh",fontWeight:"bold",
                        fontFamily:"'Open Sans',sans-serif", height:"10vh",
                        opacity:"0.1",zIndex:"1",
                        color:"#54637A",justifyContent:"center", paddingRight:"0.2vw",
                        alignItems:"center", gap:"0.5vw", borderRadius:"2%",
                        background:"none", cursor:"default",userSelect:"none",pointerEvents:'none'}}>
                            <span>{active},{intervalLabel}</span>
                    </div>
            <div style={{ display:!isLoadingChart?"flex":"none", flexDirection: "column", height: "calc(100% - 40px)", width: "100%" }}>
                    <div id={divElementId} style={{ flexBasis: "100%", flexGrow: 1, flexShrink: 1 }} />
                {/* <div id={divOverviewId} style={{ flexBasis: "20%", flexGrow: 1, flexShrink: 1 }} /> */}
                    
                    {/* <div className='waterMark'>
                            <img src={logo} alt='Logo' width={62} height={65}/>
                            <span>ExorfinGroup</span>
                    </div> */}
            </div>
            </div>
        </React.Fragment>
    );
}

export default Graph;