import { useMemo} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
export default function CloseTable({data,i18n,t}){

  const columns = useMemo(
    () => [
      {
        accessorKey: 'Id',
        header: t('OrderId'),
        size: 80, 
      },
      {
        accessorKey: 'Asset',
        header: t('Instrument'),
        size: 140, 
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span>
                    {row.original.Asset} {row.original.SubTitle}
                  </span>
            )
          },
      },
      {
        accessorKey: 'OrderType',
        header: t('Type'),
        size: 90,
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span style={row.original.OrderType === 'BUY'? {color:'#15B579',fontWeight:'bold'}: row.original.OrderType === 'SELL'? {color:'#006C9C',fontWeight:'bold'}:{}}>
                    {row.original.OrderType}/{row.original.OrderType === 'BUY'? 'LONG': row.original.OrderType === 'SELL'? 'SHORT':''}
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'Price',
        header: t('Amount'),
        size: 80, 
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span>
                    {row.original.Price?.toFixed(2)} $
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'StartPrice',
        header: t('EntryPrice'),
        size: 110, 
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span>
                    {row.original.StartPrice?.toFixed(2)} $
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'Leverage',
        header: t('Leverage'),
        size: 70, 
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span style={{backgroundColor:'#f86e4f29' , color:'#df7b18' , padding:'4px 8px' , borderRadius:'4px' }}>
                    x{row.original.Leverage} 
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'Status',
        header: t('Status'),
        size: 110,
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span style={row.original.Status === 'CLOSED'? {color:'#df7b18',padding:'4px 8px', backgroundColor:'#f86e4f29', borderRadius:'4px'}: row.original.Status === 'KILLED'? {color:'red',padding:'4px 8px', backgroundColor:'#fd2c2c30', borderRadius:'4px'}:{}}>
                    {row.original.Status}
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'Date',
        header: t('Date'),
        size: 140,
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span>
                    {moment(row.original.Date).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
            )
          }, 
      },
      {
        accessorKey: 'Profit',
        header: t('Profit'),
        size: 90,
        Cell: ({ renderedCellValue, row }) => {
            return (
                  <span style={row.original?.Profit < 0 ?{color:'#006C9C',fontWeight:'bold'}:{color:'#15B579',fontWeight:'bold'}}>

                    {row.original.Status === 'CLOSED'? row.original?.Profit.toFixed(2)+'$':<span style={{color:'#006C9C'}}>{'-' + row.original?.Price.toFixed(2)}$</span>} 
                  </span>
            )
          }, 
      },
    ],
    [i18n.language],
  );
    
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    initialState: { density: 'compact' },
    getRowId: (row) => row.Id,
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160,
    }, 
    muiTableBodyProps: {
      sx: (theme) => ({
        height:'150px',
        boxShadow:'none',
        border:'none',
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor:'#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',

          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: '#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',

          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: '#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: '#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)',
          },
          '& .MuiDataGrid-footerContainer':{
            display:"none"
        }
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: '#040718',
      draggingBorderColor: '#000',
    }),
  muiExpandButtonProps:({row}) =>({
    sx:{
      color:'#fff'

    }
  }),
    muiTableBodyCellProps: ({ column }) => ({
        sx: {
          backgroundColor:'#040718',
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          color:'#fff',
          borderBottom:'1px dashed rgb(46, 50, 54)',
          '& .MuiTableCell-root':{
            backgroundColor:'#040718',
            borderBottom:'1px dashed rgb(46, 50, 54)'
          }
        },
      }),
      muiTableProps: ({table})=>({
        sx:{
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '14px',
            '& .MuiDataGrid-footerContainer':{
                display:"none"
            }
        }
      }),
      muiTopToolbarProps: ({table})=>({
        sx:{
            display:'none',
          backgroundColor:"#040718",
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          borderTopLeftRadius:'5px',
          borderTopRightRadius:'5px',
          '& .MuiButtonBase-root':{
            color:'#fff'
          },
          '& .MuiSvgIcon-root':{
            color:'#fff'

          },
          '& .MuiInputBase-root':{
            borderColor:'#fff'

          },
          '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
          }
        }
      }),
      muiColumnActionsButtonProps: ({row})=>({
        sx:{
          color:'#fff'
        }
      }),
     muiTableHeadCellProps: {
        sx: {
          fontWeight: 'bold',
          fontFamily:"'Open Sans', sans-serif",
          fontSize: '14px',
          backgroundColor:'#040718',
          color:'#fff',
          
          borderBottom:'1px dashed rgb(46, 50, 54)',
          '& .MuiSvgIcon-root':{
            fill:'#fff'
          },
          '& .MuiDivider-root':{
            borderColor:'lightgrey'
          },
          '& .MuiInputBase-input':{
            color:'#fff',
            fontFamily:"'Open Sans', sans-serif",
          }
        },
      },
      muiTableHeadProps:{
        sx:{
          '& .MuiTableCell-root':{
            backgroundColor:'#040718',
            border:'none',
            borderTop:"1px solid rgb(46, 50, 54)",
          },
        }
      },
      muiPaginationProps: {
        showRowsPerPage: false,
        shape: 'rounded',
        sx:{
          '& .MuiButtonBase-root':{
            color:'#fff'
          }
        }
      },
      muiBottomToolbarProps:{
        sx:{
            display:'none',
          backgroundColor:'#040718',
          color:'#fff'

        },
      },
      muiFilterTextFieldProps:{
        sx:{
          color:'#fff'
        }
      },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
  });

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table}  />
    </LocalizationProvider> 
};
