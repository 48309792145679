import React, { useEffect } from 'react'
import {LinearProgress} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
export default function AutoLogin() {
    const {token,email} = useParams();
    const nav = useNavigate();
    useEffect(()=>{
        axios.get(`https://trade.exorfingroup.trade:8081/api/login/auto/${email}`,
        {
            headers:{
                "Authorization":`Bearer ${token}`
            }
        }).then((res)=>{
                console.log(res);
                secureLocalStorage.setItem("token",res.data.token);
                secureLocalStorage.setItem("user",res.data.user)
                nav('/',{replace:true});
            }).catch((err)=>{
                // setError(err.response.data.message);
            })
    },[])
  return (
    <div style={{width:"100%",height:"100vh",backgroundColor:"#000000"}}>
         <LinearProgress sx={{ width: '100%' }} color='inherit' />
    </div>
  )
}
