import React, { useEffect, useState } from 'react'
import styles from '../styles/HistoryBlock.module.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import {styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseTable from './CloseTable';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import OpenTable from './OpenTable';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Backdrop, Box, Button, Fade, FormControl, InputAdornment, Modal, OutlinedInput} from '@mui/material';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    };
const styleDark = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
    backgroundColor: '#19232C',
    width: "260px",
    color: "#fff",
    outline:'none'
};
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: 600,
        fontSize: "17px",
        marginRight: theme.spacing(2),
        color: 'rgba(255, 255, 255, 0.7)',
        padding:0,
        fontFamily:"'Open Sans',sans-serif",
        '&.Mui-selected': {
        color: '#fff',
        border:"0px solid red"
        },
        '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          children
        )}
      </div>
    );
}
export default function HistoryBlock({AssetsList,setActiveAsset,setProfit, i18n,t}) {
    // const {t} = useTranslation()
    const [value, setValue] = useState(0);
    const [history, setHistory] = useState([]);
    const [user, setUser] = useState(null);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [currentRow , setCurrentRow] = useState({})
    const [TakeProfit, setTakeProfit] = useState('0.000')
    const [StopLoss, setStopLoss] = useState('0.000')
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    useEffect(()=>{
        axios.get(`https://trade.exorfingroup.trade:8081/api/assets/user/${secureLocalStorage.getItem('user')?.Id}/history`,{
            headers:{
                'Authorization':'Bearer '+secureLocalStorage.getItem('token')
            }
        }).then((result)=>{
            setHistory(result.data.filter(el=>el.Status == 'CLOSED' || el.Status == 'KILLED'))
        }).catch(e => console.log(e))
        axios.get(`https://trade.exorfingroup.trade:8081/api/assets/user/${secureLocalStorage.getItem('user')?.Id}`,{
            headers:{
                'Authorization':'Bearer '+secureLocalStorage.getItem('token')
            }
        })
        .then((res)=>{
            setUser(secureLocalStorage.getItem('user'))
            setCurrentRecords(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
        setInterval(()=>{
            axios.get(`https://trade.exorfingroup.trade:8081/api/assets/user/${secureLocalStorage.getItem('user')?.Id}/history`,{
                headers:{
                    'Authorization':'Bearer '+secureLocalStorage.getItem('token')
                }
            }).then((result)=>{
                setHistory(result.data.filter(el=>el.Status == 'CLOSED' || el.Status == 'KILLED'))
            }).catch(e => console.log(e))
            axios.get(`https://trade.exorfingroup.trade:8081/api/assets/user/${secureLocalStorage.getItem('user')?.Id}`,{
                headers:{
                    'Authorization':'Bearer '+secureLocalStorage.getItem('token')
                }
            })
            .then((res)=>{
                setUser(secureLocalStorage.getItem('user'))
                setCurrentRecords(res.data);
            })
            .catch((err)=>{
                console.log(err);
            })
        },2000)
        
    },[])

    const handleChangeTake = (event) => {
        let newValue = event.target.value;
        const re = /^-?\d*[.,]?\d{0,4}$/;

        if (newValue === '' || re.test(newValue)) {
            setTakeProfit(newValue);
        }
    };
    const handleChangeStop = (event) => {
        let newValue = event.target.value;
        const re = /^-?\d*[.,]?\d{0,4}$/;

        if (newValue === '' || re.test(newValue)) {
            setStopLoss(newValue);
        }
    };
    useEffect(() => {
        if (currentRecords.length > 0) {
            let temp = currentRecords.reduce((sum, currentValue) => 
                sum + (currentValue.Status === 'BUY'
                    ? +parseFloat(currentValue?.Profit)?.toFixed(1)
                    : +parseFloat(currentValue?.Profit)?.toFixed(1)
                ), 0);
            setProfit(temp);
        }
    }, [currentRecords]);
    async function Submit (){
        if(currentRow.Status === 'SELL' && +TakeProfit >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) ){ 
            return
        }
        if(currentRow.Status === 'SELL' && +StopLoss <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)  && +StopLoss !== 0){ 
            return
        }
        if(currentRow.Status === 'BUY' && +TakeProfit <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) && +TakeProfit !== 0){
                return
            }
        if(currentRow.Status === 'BUY' && +StopLoss >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice) ){
            return
        }
        try{
            const {data} = await axios.patch(`https://trade.exorfingroup.trade:8081/api/assets/user/${currentRow.Id}`,{
                TakeProfit: TakeProfit?TakeProfit:0,
                StopLoss:StopLoss?StopLoss : 0
            },
            {
                headers: {
                    'Authorization': `Bearer ${secureLocalStorage.getItem('token')}`
                }
            })
            if(data) handleCloseModal()
        }catch(e){
            console.log(e)
        }


    }
    console.log(currentRow)
    return (
    <div className={styles.HistoryBlock}>
                        <Tabs value={value} onChange={handleChange} 
                        indicatorColor='transparent' sx={{height:"3vh",
                        
                        width:"100%",alignItems:"start"}}
                        >
                            <StyledTab label={t("AcquiredAssets")}/>
                            <StyledTab label={t("CloseAssets")}/>
                        </Tabs>
                        
                        <TabPanel value={value} index={0}>
                            <OpenTable i18n={i18n} t={t}
                                AssetsList={AssetsList}
                                setActiveAsset={setActiveAsset}
                                data={currentRecords} 
                                handleOpenModal={handleOpenModal}
                                setCurrentRow={setCurrentRow}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CloseTable i18n={i18n} t={t} 
                            data={history}/>
                        </TabPanel>
                        
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={styleDark}>
                        <h3 style={{ color: 'white', width: '100%', textAlign: 'center', fontFamily: "'Open Sans',sans-serif", margin: '0px' }}>
                        {t("TakeProfit")}/{t("StopLoss")} <br /> {t("ForPossition")}
                        </h3>
                        <div style={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'start', gap: '7px' }}>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                <span>{t("OrderType")}</span>
                                <span className={styles.status}
                                    style={currentRow.Status === 'BUY'
                                        ? { padding: '5px', borderRadius: '4px', color: 'rgb(119, 237, 139)', backgroundColor: 'rgba(34, 197, 94, 0.16)', fontWeight: '800' }
                                        : { padding: '5px', borderRadius: '4px', color: 'rgb(255, 48, 48)', backgroundColor: 'rgba(255, 86, 48, 0.16)', fontWeight: '800' }}>
                                    {currentRow.Status}{currentRow.Status === 'BUY' ? '/LONG' : "/SHORT"}
                                </span>
                            </div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                <span>{t("Asset")}</span>
                                <span style={{ fontWeight: "bold" }}>{currentRow?.Subtitle?.slice(1, -1)}/USD</span>
                            </div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                <span>{t("Leverage")}</span>
                                <span style={{
                                    color: '#DF7B18',
                                    backgroundColor: "rgba(248, 110, 79, 0.16)",
                                    padding: "4px",
                                    borderRadius: "4px",
                                    fontWeight: '600'
                                }}>x{currentRow.Leverage}</span>
                            </div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                <span>{t("EntryPrice")}</span>
                                <span style={{ fontWeight: '600' }}>{+currentRow?.StartPrice?.toFixed(4)}</span>
                            </div>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                                <span>{t("OrderPrice")}</span>
                                <span style={{ fontWeight: '600' }}>{(parseFloat(currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice))?.toFixed(4)}</span>
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <FormControl
                                color="warning" fullWidth sx={{
                                    width: '100%',

                                    '& .MuiTypography-root': {
                                        color: '#fff'
                                    }, '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                    },
                                }}>
                                <OutlinedInput
                                    type="number"
                                    sx={{
                                        color: "#fff",
                                        fontFamily: "'Open Sans',sans-serif",
                                        appearance: 'none',
                                        outline: 'none',
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            color: "#fff",
                                            MozAppearance: "textfield",
                                            textAlign: 'right'
                                        },
                                    }}

                                    id="outlined-adornment-amount"
                                    placeholder='0.000'
                                    startAdornment={<InputAdornment sx={{ color: "grey" }} position="start"><span style={{ color: 'grey' }}>{t("TakeProfit")} USD | </span></InputAdornment>}
                                    value={TakeProfit} onChange={(e) => handleChangeTake(e)} min={0}
                                />
                            </FormControl>
                            {currentRow.Status === 'BUY'
                                ? +TakeProfit <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)?.toFixed(4) && +TakeProfit
                                    ? <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t('MustBeHigher')}</p>
                                    : '' : currentRow.Status === 'SELL' ? +TakeProfit >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)?.toFixed(4) ?
                                        <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t('MustBeLower')}</p> : "" : ''
                            }
                            <p style={+currentRow.StartPrice < +TakeProfit ? { width: '100%', color: 'rgb(132, 142, 156)', lineHeight: '20px', fontSize: '13px', textAlign: 'justify' } : { width: '100%', color: 'rgb(132, 142, 156)', lineHeight: '20px', fontSize: '13px', textAlign: 'justify' }}>
                                {t("WhenMarketPrice")} <span style={{ color: '#fff' }}>{TakeProfit}</span>{t('MarketTakeProfit')}
                                {
                                    !TakeProfit || +TakeProfit === 0 ? ' -- USD' :
                                        <span style={currentRow.Status === 'BUY' ?
                                            parseFloat(((TakeProfit * currentRow.Count) - (currentRow.StartPrice * currentRow.Count)) * currentRow.Leverage) > 0 ?
                                                { color: "rgb(119, 237, 139)" } :
                                                { color: "#F6465D" } :
                                            currentRow.Status === 'SELL' ?
                                            parseFloat(((TakeProfit * currentRow.Count) - (currentRow.StartPrice * currentRow.Count)) * currentRow.Leverage) * -1 > 0 ?
                                                { color: "rgb(119, 237, 139)" } :
                                                { color: "#F6465D" }:{}}
                                        >
                                            {currentRow.Status === 'BUY' ? ' ' + parseFloat((TakeProfit - currentRow.StartPrice) * currentRow.Count * currentRow.LotSize).toFixed(4) : ' ' + parseFloat((currentRow.StartPrice - TakeProfit) * currentRow.Count * currentRow.LotSize).toFixed(4)} USD</span>
                                }

                            </p>
                            <FormControl
                                color="warning" fullWidth sx={{
                                    width: '100%',

                                    '& .MuiTypography-root': {
                                        color: '#fff'
                                    }, '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightgrey',
                                        },
                                    },
                                }}>
                                <OutlinedInput
                                    type="number"
                                    sx={{
                                        color: "#fff",
                                        fontFamily: "'Open Sans',sans-serif",
                                        appearance: 'none',
                                        outline: 'none',
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            color: "#fff",
                                            MozAppearance: "textfield",
                                            textAlign: 'right'
                                        },
                                    }}
                                    blurOnSelect
                                    id="outlined-adornment-amount"
                                    placeholder='0.000'
                                    startAdornment={<InputAdornment sx={{ color: "grey" }} position="start"><span style={{ color: 'grey' }}>{t("StopLossCapitalize")} USD | </span></InputAdornment>}
                                    value={StopLoss} onChange={(e) => handleChangeStop(e)} min={0}
                                />
                            </FormControl>
                            {currentRow.Status === 'BUY'
                                ? +StopLoss >= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)?.toFixed(4)
                                    ? <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t("MustBeLower")}</p>
                                    : '' : currentRow.Status === 'SELL' ? +StopLoss <= (currentRecords.find(el=> el.AssetId == currentRow?.AssetId)?.MarketPrice)?.toFixed(4) && +StopLoss !==0 ?
                                        <p style={{ margin: '0', color: '#F6465D', fontSize: '12px' }}>{t("MustBeHigher")}</p> : "" : ''
                            }
                            <p style={+currentRow.StartPrice < +StopLoss ? { width: '100%', color: 'rgb(132, 142, 156)', lineHeight: '20px', fontSize: '13px', textAlign: 'justify' } : { width: '100%', color: 'rgb(132, 142, 156)', lineHeight: '20px', fontSize: '13px', textAlign: 'justify' }}>
                                {t('WhenMarketPrice')} <span style={{ color: '#fff' }}>{StopLoss}</span>{t('MarketStopLoss')}
                                {
                                    !StopLoss || +StopLoss === 0 ? ' -- USD' :
                                        <span style={currentRow.Status === 'BUY' ?
                                            parseFloat(((StopLoss * currentRow.Count) - (currentRow.StartPrice * currentRow.Count)) * currentRow.Leverage) > 0 ?
                                                { color: "rgb(119, 237, 139)" } :
                                                { color: "#F6465D" } :
                                            currentRow.Status === 'SELL' ?
                                            parseFloat(((StopLoss * currentRow.Count) - (currentRow.StartPrice * currentRow.Count)) * currentRow.Leverage) * -1 > 0 ?
                                                { color: "rgb(119, 237, 139)" } :
                                                { color: "#F6465D" }:{}}
                                        >
                                            {currentRow.Status === 'BUY' ? ' ' + parseFloat((StopLoss - currentRow.StartPrice) * currentRow.Count * currentRow.LotSize)?.toFixed(4) : ' ' + parseFloat((currentRow.StartPrice - StopLoss) * currentRow.Count * currentRow.LotSize).toFixed(4)} USD</span>
                                }

                            </p>
                            <div className={styles.buttons} style={{ width: '100%', textAlign: 'right' }}>
                                <Button style={{
                                    padding: '10px 15px', border: 'none', borderRadius: '4px',
                                    color: '#000',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    marginRight: '5px',
                                    backgroundColor: '#fff',
                                    fontFamily: "'Open Sans',sans-serif",
                                    fontWeight: '600'
                                }}
                                onClick={Submit}
                                >{t('Confirm')}
                                </Button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseModal()}
                                sx={{
                                    position: 'absolute',

                                    top: '-16px',
                                    width: '40px',
                                    height: '40px',
                                    right: '-16px',
                                    fill: '#fff',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
    </div>
  )
}
